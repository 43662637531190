<template>
  <div class="admin appointments">
    <header class="admin-header cf">
      <h1>
        <router-link to="/admin/locations">Standorte</router-link>
        <p>
          {{ location.name }}
        </p>
      </h1>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>
      <div class="row row-gutter-20">
        <div class="col-12">
          <div class="content-wrap">
            <h3>Standort</h3>

            <div class="form-wrap select-wrap">
              <label for="status">Status</label>
              <select v-model="location.status" class="form-input">
                <option key="hidden" value="hidden">Ausgeblendet</option>
                <option key="visible" value="visible">Sichtbar</option>
              </select>
            </div>

            <div class="form-wrap">
              <label for="name" class="form-label">Name</label>
              <input
                v-model="location.name"
                class="form-input input-grey"
                type="text"
                name="name"
                id="name"
              />
            </div>

            <div v-if="languages.length > 1" class="translation-wrap">
              <div v-for="lang in languages" v-bind:key="lang.id">
                <div v-if="lang.slug != 'de'" class="form-wrap">
                  <label :for="'name_' + lang.id" class="form-label"
                    >Name ({{ lang.name }})</label
                  >
                  <input
                    v-model="location['name_i18n_' + lang.slug]"
                    class="form-input input-grey"
                    type="text"
                    :name="'name_' + lang.id"
                    :id="'name_' + lang.id"
                  />
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <label for="description">Beschreibung</label>
              <textarea
                v-model="location.description"
                id="description"
                name="description"
                rows="4"
                class="form-input"
              ></textarea>
            </div>

            <div class="form-wrap">
              <label for="web_url" class="form-label">Link zur Webseite</label>
              <input
                v-model="location.web_url"
                class="form-input"
                type="text"
                name="web_url"
                id="web_url"
              />
            </div>

            <div v-if="languages.length > 1" class="translation-wrap">
              <div v-for="lang in languages" v-bind:key="lang.id">
                <div v-if="lang.slug != 'de'" class="form-wrap">
                  <label :for="'description_' + lang.id" class="form-label"
                    >Beschreibung ({{ lang.name }})</label
                  >
                  <textarea
                    v-model="location['description_i18n_' + lang.slug]"
                    :name="'description_' + lang.id"
                    :id="'description_' + lang.id"
                    rows="4"
                    class="form-input"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <label for="info_body">Info oder Ankündigung</label>
              <textarea
                v-model="location.info_body"
                id="info_body"
                name="info_body"
                rows="3"
                class="form-input"
              ></textarea>
            </div>

            <div class="form-wrap">
              <label for="email_body">E-Mail Beschreibung</label>
              <textarea
                v-model="location.email_body"
                id="email_body"
                name="email_body"
                rows="3"
                class="form-input"
              ></textarea>
            </div>

            <div class="form-wrap">
              <label for="name" class="form-label"
                >Termin E-Mail per CC an</label
              >
              <input
                v-model="location.cc_email"
                class="form-input input-grey"
                type="text"
                name="name"
                id="name"
              />
            </div>

            <div
              v-if="location.appointment_mode == 'staff_based'"
              class="form-wrap select-wrap"
            >
              <label for="cc_email_user"
                >Termin E-Mail per CC an Mitarbeiter</label
              >
              <select v-model="location.cc_email_user" class="form-input">
                <option key="true" value="true">Ja</option>
                <option key="false" value="false">Nein</option>
              </select>
            </div>

            <div class="form-wrap">
              <label for="address" class="form-label">Adresse</label>
              <input
                v-model="location.address"
                class="form-input input-grey"
                type="text"
                name="address"
                id="address"
              />
            </div>

            <div class="form-wrap select-wrap">
              <label for="status">Ausdruck für Bezahlung</label>
              <select v-model="location.payment_type" class="form-input">
                <option key="bon_printer" value="bon_printer">
                  Bon-Drucker (bei den Terminen)
                </option>
                <option key="laser_printer" value="laser_printer">
                  DIN A4 Quittung (bei den Quittungen)
                </option>
              </select>
            </div>

            <div class="form-wrap" style="margin: 20px 0 10px 0">
              <input
                v-model="location.cc_email_description"
                type="checkbox"
                id="cc_email_description"
                name="cc_email_description"
                style="
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;
                "
              />
              <label
                for="cc_email_description"
                style="display: inline-block; vertical-align: middle; margin: 0"
                >Nur wenn das Feld "Beschreibung" ausgefüllt wird?</label
              >
            </div>

            <div class="form-wrap" style="margin: 20px 0 10px 0">
              <input
                v-model="location.feature_waiting_line"
                type="checkbox"
                id="feature_waiting_line"
                name="feature_waiting_line"
                style="
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;
                "
              />
              <label
                for="feature_waiting_line"
                style="display: inline-block; vertical-align: middle; margin: 0"
                >Wird die Warteschlangen-Funktion genutzt?</label
              >
            </div>

            <div class="form-wrap" style="margin: 20px 0 10px 0">
              <input
                v-model="location.feature_auto_checkin"
                type="checkbox"
                id="feature_auto_checkin"
                name="feature_auto_checkin"
                style="
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;
                "
              />
              <label
                for="feature_auto_checkin"
                style="display: inline-block; vertical-align: middle; margin: 0"
                >Sollen die Termine automatisch eingecheckt werden?</label
              >
            </div>

            <div class="form-wrap" style="margin: 20px 0 10px 0">
              <input
                v-model="location.feature_letter_coding"
                type="checkbox"
                id="feature_letter_coding"
                name="feature_letter_coding"
                style="
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;
                "
              />
              <label
                for="feature_letter_coding"
                style="
                  width: calc(100% - 50px);
                  display: inline-block;
                  vertical-align: top;
                  margin: 0;
                "
                >Hat Leistungen, bei denen die Buchstabenkennzeichnung für die
                Zuordnung relevant ist? Hierbei werden die Kontaktdaten vor der
                Terminauswahl abgefragt.
              </label>
            </div>

            <hr style="margin: 25px 0 20px 0" />
            <div class="form-wrap" style="margin: 0 0 20px 0">
              <input
                v-model="location.ics_enabled"
                type="checkbox"
                id="ics_enabled"
                name="ics_enabled"
                style="
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;
                "
              />
              <label
                for="ics_enabled"
                style="display: inline-block; vertical-align: middle; margin: 0"
                >E-Mail Kalender Einladung? (.ics Datei)</label
              >
            </div>

            <div v-if="location.ics_enabled">
              <div class="form-wrap">
                <label for="ics_email" class="form-label"
                  >Kalender Einladung: Absender E-Mail</label
                >
                <input
                  v-model="location.ics_email"
                  class="form-input"
                  type="text"
                  name="ics_email"
                  id="ics_email"
                />
              </div>

              <div class="form-wrap">
                <label for="ics_name" class="form-label"
                  >Kalender Einladung: Absender Name</label
                >
                <input
                  v-model="location.ics_name"
                  class="form-input"
                  type="text"
                  name="ics_name"
                  id="ics_name"
                />
              </div>

              <div class="form-wrap">
                <label for="ics_description" class="form-label"
                  >Kalender Einladung: Beschreibung</label
                >
                <input
                  v-model="location.ics_description"
                  class="form-input"
                  type="text"
                  name="ics_description"
                  id="ics_description"
                />
              </div>

              <div class="form-wrap">
                <label for="ics_location" class="form-label"
                  >Kalender Einladung: Absender Standort/Adresse</label
                >
                <input
                  v-model="location.ics_location"
                  class="form-input"
                  type="text"
                  name="ics_location"
                  id="ics_location"
                />
              </div>
            </div>

            <hr style="margin: 25px 0 20px 0" />

            <div class="form-wrap" style="margin: 0 0 10px 0">
              <input
                v-model="location.send_reminder_24h"
                type="checkbox"
                id="send_reminder_24h"
                name="send_reminder_24h"
                style="
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;
                "
              />
              <label
                for="send_reminder_24h"
                style="display: inline-block; vertical-align: middle; margin: 0"
                >Die Bürger einen Tag vor dem Termin per Mail erinnern?</label
              >
            </div>

            <div class="form-wrap" style="margin: 10px 0 20px 0">
              <input
                v-model="location.send_reminder_72h"
                type="checkbox"
                id="send_reminder_72h"
                name="send_reminder_72h"
                style="
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;
                "
              />
              <label
                for="send_reminder_72h"
                style="display: inline-block; vertical-align: middle; margin: 0"
                >Die Bürger drei Tage vor dem Termin per Mail erinnern?</label
              >
            </div>

            <hr style="margin: 25px 0 20px 0" />

            <p style="margin-bottom: 0; text-align: right">
              <a @click="update_location" class="button button-red">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="floppy-disk"
                  class="svg-inline--fa fa-floppy-disk"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"
                  ></path>
                </svg>
                <span>Daten speichern ›</span>
              </a>
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="content-wrap content-termine">
            <h3>Terminplanung</h3>

            <div class="form-wrap select-wrap">
              <label for="status">Terminplanungsmodus</label>
              <select v-model="location.appointment_mode" class="form-input">
                <option key="pool_based" value="pool_based">
                  Modus 1: Terminpool
                </option>
                <option key="staff_based" value="staff_based">
                  Modus 2: Mitarbeiter
                </option>
              </select>
            </div>

            <div class="form-wrap">
              <label for="first_appointment_delay" class="form-label"
                >Terminvorlauf (in Minuten)</label
              >
              <input
                v-model="location.first_appointment_delay"
                class="form-input"
                type="text"
                name="first_appointment_delay"
                id="first_appointment_delay"
              />
            </div>

            <p style="margin-bottom: 0; text-align: left">
              <a @click="update_location" class="button button-red">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="floppy-disk"
                  class="svg-inline--fa fa-floppy-disk"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"
                  ></path>
                </svg>
                <span>Speichern ›</span>
              </a>
            </p>

            <p
              style="
                font-size: 12px;
                line-height: 1.4;
                background: rgb(244, 248, 255);
                color: #084298;
                padding: 14px;
                border: 1px dashed #b6d4fe;
              "
            >
              Die Terminplanung basiert auf der Anzahl an Terminen, die parallel
              erstellt werden können. Der Grundwert findet sich unter dem Punkt
              "Langfristig". Sollte kein Wochen- oder Tagesbasierter-Eintrag
              existieren, greift immer der langfristige Grundwert. Der Tageswert
              hat die höchste Prio und überschreibt den Wochen- und Grundwert.
            </p>

            <h4>Langfristig</h4>
            <p class="sub">
              Die Anzahl der Termine, die parallel in den nächsten
              {{ location.days_ahead }} Tagen erstellt werden können.
            </p>

            <div class="form-wrap">
              <label for="slot_amount" class="form-label"
                >Wie viele Termine sollen parallel hinterlegt werden
                können?</label
              >
              <input
                v-model="location.slot_amount"
                class="form-input input-grey"
                type="text"
                name="slot_amount"
                id="slot_amount"
              />
            </div>

            <div class="form-wrap">
              <label for="slot_interval" class="form-label"
                >Terminvereinbarung Intervall (in min.)</label
              >
              <input
                v-model="location.slot_interval"
                class="form-input input-grey"
                type="text"
                name="slot_interval"
                id="slot_interval"
              />
            </div>

            <p style="margin-bottom: 0; text-align: left">
              <a @click="update_location" class="button button-red">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="floppy-disk"
                  class="svg-inline--fa fa-floppy-disk"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"
                  ></path>
                </svg>
                <span>Langfristige Terminplanung speichern ›</span>
              </a>
            </p>

            <hr style="margin: 25px 0 0 0" />

            <h4>Kalenderwochen</h4>
            <p class="sub">
              Die Anzahl der Termine, die parallel in der hinterlegten KW
              erstellt werden können.
            </p>
            <p style="margin-top: 0">
              <a
                @click="open_week_entry"
                class="button button-light-gray button-small"
                style="font-size: 0.7rem; padding: 0.3rem 0.8rem"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span>Eintrag anlegen</span>
              </a>
            </p>

            <div v-if="scheduling_entries_week.length == 0">
              <p
                style="
                  font-size: 12px;
                  line-height: 1.4;
                  background: rgb(244, 248, 255);
                  color: #084298;
                  padding: 14px;
                  border: 1px dashed #b6d4fe;
                "
              >
                Aktuell sind keine wochenbasierten Einträge vorhanden.
              </p>
            </div>
            <div v-else>
              <p
                v-for="entry in scheduling_entries_week"
                v-bind:key="entry.id"
                class="entry-item"
              >
                <span v-if="entry.entry_val == 1"
                  >KW {{ entry.cw }}: {{ entry.entry_val }} Termin
                  parallel</span
                >
                <span v-else
                  >KW {{ entry.cw }}: {{ entry.entry_val }} Termine
                  parallel</span
                >
                <svg
                  @click="delete_entry(entry)"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </p>
            </div>

            <hr style="margin: 25px 0 0 0" />

            <h4>Wochentage</h4>
            <p class="sub">
              Die Anzahl der Termine, die parallel an einem bestimmten Wochentag
              erstellt werden können.
            </p>
            <p style="margin-top: 0">
              <a
                @click="open_weekday_entry"
                class="button button-light-gray button-small"
                style="font-size: 0.7rem; padding: 0.3rem 0.8rem"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span>Eintrag anlegen</span>
              </a>
            </p>

            <div v-if="scheduling_entries_weekday.length == 0">
              <p
                style="
                  font-size: 12px;
                  line-height: 1.4;
                  background: rgb(244, 248, 255);
                  color: #084298;
                  padding: 14px;
                  border: 1px dashed #b6d4fe;
                "
              >
                Aktuell sind keine Wochentagbasierten Einträge vorhanden.
              </p>
            </div>
            <div v-else>
              <p
                v-for="entry in scheduling_entries_weekday"
                v-bind:key="entry.id"
                class="entry-item"
              >
                <span v-if="entry.entry_val == 1"
                  >{{ entry.weekday_formatted }}: {{ entry.entry_val }} Termin
                  parallel</span
                >
                <span v-else
                  >{{ entry.weekday_formatted }}: {{ entry.entry_val }} Termine
                  parallel</span
                >
                <svg
                  @click="delete_entry(entry)"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </p>
            </div>

            <hr style="margin: 25px 0 0 0" />

            <h4>Bestimmte Zeitspanne bei Wochentagen</h4>
            <p class="sub">
              Die Anzahl der Termine, die parallel an dem hinterlegten Wochentag
              innerhalb eines gewissen Zeitraums erstellt werden können.
            </p>
            <p style="margin-top: 0">
              <a
                @click="open_weekday_hour_entry"
                class="button button-light-gray button-small"
                style="font-size: 0.7rem; padding: 0.3rem 0.8rem"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span>Eintrag anlegen</span>
              </a>
            </p>

            <div v-if="scheduling_entries_weekday_hour.length == 0">
              <p
                style="
                  font-size: 12px;
                  line-height: 1.4;
                  background: rgb(244, 248, 255);
                  color: #084298;
                  padding: 14px;
                  border: 1px dashed #b6d4fe;
                "
              >
                Aktuell sind keine Wochentagbasierten Einträge mit Zeitspanne
                vorhanden.
              </p>
            </div>
            <div v-else>
              <p
                v-for="entry in scheduling_entries_weekday_hour"
                v-bind:key="entry.id"
                class="entry-item"
              >
                <span v-if="entry.entry_val == 1"
                  >{{ entry.weekday_formatted }} von
                  {{ entry.time_from_formatted }} Uhr bis
                  {{ entry.time_to_formatted }} Uhr:
                  {{ entry.entry_val }} Termin parallel</span
                >
                <span v-else
                  >{{ entry.weekday_formatted }} von
                  {{ entry.time_from_formatted }} Uhr bis
                  {{ entry.time_to_formatted }} Uhr:
                  {{ entry.entry_val }} Termine parallel</span
                >
                <svg
                  @click="delete_entry(entry)"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </p>
            </div>

            <hr style="margin: 25px 0 0 0" />

            <h4>Einzelne Tage</h4>
            <p class="sub">
              Die Anzahl der Termine, die parallel an einem bestimmten Tag
              erstellt werden können.
            </p>
            <p style="margin-top: 0">
              <a
                @click="open_day_entry"
                class="button button-light-gray button-small"
                style="font-size: 0.7rem; padding: 0.3rem 0.8rem"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span>Eintrag anlegen</span>
              </a>
            </p>

            <div v-if="scheduling_entries_day.length == 0">
              <p
                style="
                  font-size: 12px;
                  line-height: 1.4;
                  background: rgb(244, 248, 255);
                  color: #084298;
                  padding: 14px;
                  border: 1px dashed #b6d4fe;
                "
              >
                Aktuell sind keine Tagesbasierten Einträge vorhanden.
              </p>
            </div>
            <div v-else>
              <p
                v-for="entry in scheduling_entries_day"
                v-bind:key="entry.id"
                class="entry-item"
              >
                <span v-if="entry.entry_val == 1"
                  >{{ entry.day_formatted }}: {{ entry.entry_val }} Termin
                  parallel</span
                >
                <span v-else
                  >{{ entry.day_formatted }}: {{ entry.entry_val }} Termine
                  parallel</span
                >
                <svg
                  @click="delete_entry(entry)"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </p>
            </div>

            <hr style="margin: 25px 0 0 0" />

            <h4>Bestimmte Zeitspanne bei einzelnen Tagen</h4>
            <p class="sub">
              Die Anzahl der Termine, die parallel an dem hinterlegten Tag
              innerhalb eines gewissen Zeitraums erstellt werden können.
            </p>
            <p style="margin-top: 0">
              <a
                @click="open_day_hour_entry"
                class="button button-light-gray button-small"
                style="font-size: 0.7rem; padding: 0.3rem 0.8rem"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span>Eintrag anlegen</span>
              </a>
            </p>

            <div v-if="scheduling_entries_day_hour.length == 0">
              <p
                style="
                  font-size: 12px;
                  line-height: 1.4;
                  background: rgb(244, 248, 255);
                  color: #084298;
                  padding: 14px;
                  border: 1px dashed #b6d4fe;
                "
              >
                Aktuell sind keine Tagesbasierten Einträge mit Zeitspanne
                vorhanden.
              </p>
            </div>
            <div v-else>
              <p
                v-for="entry in scheduling_entries_day_hour"
                v-bind:key="entry.id"
                class="entry-item"
              >
                <span v-if="entry.entry_val == 1"
                  >{{ entry.day_formatted }} von
                  {{ entry.time_from_formatted }} Uhr bis
                  {{ entry.time_to_formatted }} Uhr:
                  {{ entry.entry_val }} Termin parallel</span
                >
                <span v-else
                  >{{ entry.day_formatted }} von
                  {{ entry.time_from_formatted }} Uhr bis
                  {{ entry.time_to_formatted }} Uhr:
                  {{ entry.entry_val }} Termine parallel</span
                >
                <svg
                  @click="delete_entry(entry)"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </p>
            </div>

            <!--<div class="form-wrap">
              <label for="days_ahead" class="form-label">Wie viele Tage im Voraus sollen Termine gebucht werden können?</label>
              <input v-model="location.days_ahead" class="form-input input-grey" type="text" name="days_ahead" id="days_ahead">
            </div>-->
          </div>

          <div class="content-wrap">
            <h3>Öffungszeiten</h3>

            <div
              v-if="opening_hours && opening_hours.length == 0"
              class="alert alert-info"
              style="margin-bottom: 0"
            >
              <p>Noch keine Öffnungszeiten hinterlegt.</p>
            </div>
            <div v-else>
              <ul class="content-list">
                <li v-for="hour in opening_hours" v-bind:key="hour.id">
                  <span v-if="hour.working_day == 'monday'">Montag:</span>
                  <span v-if="hour.working_day == 'tuesday'">Dienstag:</span>
                  <span v-if="hour.working_day == 'wednesday'">Mittwoch:</span>
                  <span v-if="hour.working_day == 'thursday'">Donnerstag:</span>
                  <span v-if="hour.working_day == 'friday'">Freitag:</span>
                  <span v-if="hour.working_day == 'saturday'">Samstag:</span>
                  <span v-if="hour.working_day == 'sunday'">Sonntag:</span>
                  {{ hour.start_formatted }} Uhr – {{ hour.end_formatted }} Uhr

                  <svg
                    @click="delete_hour(hour)"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="xmark"
                    class="svg-inline--fa fa-xmark"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                    ></path>
                  </svg>
                </li>
              </ul>
            </div>

            <p style="text-align: right; margin-bottom: 0">
              <a
                @click="modal_add_opening_hour = true"
                class="button button-red"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="plus"
                  class="svg-inline--fa fa-plus"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                  ></path>
                </svg>
                <span>Öffnungszeit hinzufügen ›</span>
              </a>
            </p>
          </div>

          <div class="content-wrap">
            <h3>Servicezeiten</h3>

            <div
              v-if="service_hours && service_hours.length == 0"
              class="alert alert-info"
              style="margin-bottom: 0"
            >
              <p>Noch keine Servicezeiten hinterlegt.</p>
            </div>
            <div v-else>
              <ul class="content-list">
                <li v-for="hour in service_hours" v-bind:key="hour.id">
                  <span v-if="hour.working_day == 'monday'">Montag:</span>
                  <span v-if="hour.working_day == 'tuesday'">Dienstag:</span>
                  <span v-if="hour.working_day == 'wednesday'">Mittwoch:</span>
                  <span v-if="hour.working_day == 'thursday'">Donnerstag:</span>
                  <span v-if="hour.working_day == 'friday'">Freitag:</span>
                  <span v-if="hour.working_day == 'saturday'">Samstag:</span>
                  <span v-if="hour.working_day == 'sunday'">Sonntag:</span>
                  {{ hour.start_formatted }} Uhr – {{ hour.end_formatted }} Uhr

                  <small
                    v-if="
                      hour.valid_from_formatted || hour.valid_until_formatted
                    "
                    style="display: block"
                    >Mit folgendem Zeitraum –
                    <span v-if="hour.valid_from_formatted"
                      >Start: {{ hour.valid_from_formatted }} </span
                    ><span v-if="hour.valid_until_formatted">
                      | Ende:{{ hour.valid_until_formatted }}</span
                    ></small
                  >

                  <svg
                    @click="delete_service_hour(hour)"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="xmark"
                    class="svg-inline--fa fa-xmark"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                    ></path>
                  </svg>
                </li>
              </ul>
            </div>

            <p style="text-align: right; margin-bottom: 0">
              <a
                @click="modal_add_service_hour = true"
                class="button button-red"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="plus"
                  class="svg-inline--fa fa-plus"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                  ></path>
                </svg>
                <span>Servicezeit hinzufügen ›</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <vue-final-modal
      classes="modal-wrap"
      content-class="modal-inner"
      v-model="modal_add_opening_hour"
    >
      <svg
        @click="modal_add_opening_hour = false"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
      <div class="modal-head">
        <h3>Öffnungszeit hinzufügen</h3>
      </div>
      <div class="modal-body">
        <div class="form-wrap select-wrap">
          <label for="working_day">Wochentag</label>
          <select v-model="opening_hour.working_day" class="form-input">
            <option key="monday" value="monday">Montag</option>
            <option key="tuesday" value="tuesday">Dienstag</option>
            <option key="wednesday" value="wednesday">Mittwoch</option>
            <option key="thursday" value="thursday">Donnerstag</option>
            <option key="friday" value="friday">Freitag</option>
            <option key="saturday" value="saturday">Samstag</option>
            <option key="sunday" value="sunday">Sonntag</option>
          </select>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="start" class="form-label">Von</label>
              <input
                v-model="opening_hour.start"
                class="form-input input-grey"
                type="time"
                name="start"
                id="start"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="end" class="form-label">Bis</label>
              <input
                v-model="opening_hour.end"
                class="form-input input-grey"
                type="time"
                name="end"
                id="end"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-meta">
        <a @click="create_opening_hour" class="button button-red button-100"
          >Öffnungszeit erstellen ›</a
        >
      </div>
    </vue-final-modal>

    <vue-final-modal
      classes="modal-wrap"
      content-class="modal-inner"
      v-model="modal_add_service_hour"
    >
      <svg
        @click="modal_add_service_hour = false"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
      <div class="modal-head">
        <h3>Servicezeit hinzufügen</h3>
      </div>
      <div class="modal-body">
        <div class="form-wrap select-wrap">
          <label for="working_day">Wochentag</label>
          <select v-model="service_hour.working_day" class="form-input">
            <option key="monday" value="monday">Montag</option>
            <option key="tuesday" value="tuesday">Dienstag</option>
            <option key="wednesday" value="wednesday">Mittwoch</option>
            <option key="thursday" value="thursday">Donnerstag</option>
            <option key="friday" value="friday">Freitag</option>
            <option key="saturday" value="saturday">Samstag</option>
            <option key="sunday" value="sunday">Sonntag</option>
          </select>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="start" class="form-label">Von</label>
              <input
                v-model="service_hour.start"
                class="form-input input-grey"
                type="time"
                name="start"
                id="start"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="end" class="form-label">Bis</label>
              <input
                v-model="service_hour.end"
                class="form-input input-grey"
                type="time"
                name="end"
                id="end"
              />
            </div>
          </div>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="valid_from" class="form-label"
                >Zeitraum Start (optional)</label
              >
              <input
                v-model="service_hour.valid_from"
                class="form-input input-grey"
                type="date"
                name="valid_from"
                id="valid_from"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="valid_until" class="form-label"
                >Zeitraum Ende (optional)</label
              >
              <input
                v-model="service_hour.valid_until"
                class="form-input input-grey"
                type="date"
                name="valid_until"
                id="valid_until"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-meta">
        <a @click="create_service_hour" class="button button-red button-100"
          >Servicezeit erstellen ›</a
        >
      </div>
    </vue-final-modal>

    <vue-final-modal
      classes="modal-wrap"
      content-class="modal-inner"
      v-model="modal_add_day_entry"
    >
      <svg
        @click="modal_add_day_entry = false"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
      <div class="modal-head">
        <h3>Tageseintrag hinzufügen</h3>
      </div>
      <div class="modal-body">
        <div class="form-wrap">
          <label for="day" class="form-label">Bitte den Tag auswählen:</label>
          <v-date-picker
            v-model="day_entry.day"
            :first-day-of-week="2"
            locale="de"
            color="red"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="bg-white border px-2 py-1 rounded form-input"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>

        <div class="form-wrap">
          <label for="entry_val" class="form-label"
            >Wie viele Termine sollen parallel an diesem Tag erlaubt
            sein?</label
          >
          <input
            v-model="day_entry.entry_val"
            class="form-input"
            type="text"
            name="entry_val"
            id="entry_val"
          />
        </div>
      </div>
      <div class="modal-meta">
        <a @click="create_day_entry" class="button button-red button-100"
          >Tageseintrag erstellen ›</a
        >
      </div>
    </vue-final-modal>

    <vue-final-modal
      classes="modal-wrap"
      content-class="modal-inner"
      v-model="modal_add_week_entry"
    >
      <svg
        @click="modal_add_week_entry = false"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
      <div class="modal-head">
        <h3>Wocheneintrag hinzufügen</h3>
      </div>
      <div class="modal-body">
        <div class="form-wrap">
          <label for="cw" class="form-label">Woche</label>
          <input
            v-model="week_entry.cw"
            class="form-input input-grey"
            type="text"
            name="cw"
            id="cw"
          />
        </div>

        <div class="form-wrap">
          <label for="entry_val_week" class="form-label"
            >Wie viele Termine sollen parallel in dieser Woche erlaubt
            sein?</label
          >
          <input
            v-model="week_entry.entry_val"
            class="form-input"
            type="text"
            name="entry_val_week"
            id="entry_val_week"
          />
        </div>
      </div>
      <div class="modal-meta">
        <a @click="create_week_entry" class="button button-red button-100"
          >Wocheneintrag erstellen ›</a
        >
      </div>
    </vue-final-modal>

    <vue-final-modal
      classes="modal-wrap"
      content-class="modal-inner"
      v-model="modal_add_weekday_entry"
    >
      <svg
        @click="modal_add_weekday_entry = false"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
      <div class="modal-head">
        <h3>Wochentag hinzufügen</h3>
      </div>
      <div class="modal-body">
        <div class="form-wrap">
          <div class="form-wrap select-wrap">
            <label for="weekday" class="form-label"
              >Bitte den Wochentag auswählen:</label
            >
            <select v-model="weekday_entry.weekday" class="form-input">
              <option key="1" value="1">Montag</option>
              <option key="2" value="2">Dienstag</option>
              <option key="3" value="3">Mittwoch</option>
              <option key="4" value="4">Donnerstag</option>
              <option key="5" value="5">Freitag</option>
              <option key="6" value="6">Samstag</option>
              <option key="7" value="7">Sonntag</option>
            </select>
          </div>
        </div>

        <div class="form-wrap">
          <label for="entry_val" class="form-label"
            >Wie viele Termine sollen parallel an diesem Wochentag erlaubt
            sein?</label
          >
          <input
            v-model="weekday_entry.entry_val"
            class="form-input"
            type="text"
            name="entry_val"
            id="entry_val"
          />
        </div>
      </div>
      <div class="modal-meta">
        <a @click="create_weekday_entry" class="button button-red button-100"
          >Eintrag erstellen ›</a
        >
      </div>
    </vue-final-modal>

    <vue-final-modal
      classes="modal-wrap"
      content-class="modal-inner"
      v-model="modal_add_weekday_hour_entry"
    >
      <svg
        @click="modal_add_weekday_hour_entry = false"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
      <div class="modal-head">
        <h3>Wochentag Zeitspanne hinzufügen</h3>
      </div>
      <div class="modal-body">
        <div class="form-wrap">
          <div class="form-wrap select-wrap">
            <label for="weekday" class="form-label"
              >Bitte den Wochentag auswählen:</label
            >
            <select v-model="weekday_hour_entry.weekday" class="form-input">
              <option key="1" value="1">Montag</option>
              <option key="2" value="2">Dienstag</option>
              <option key="3" value="3">Mittwoch</option>
              <option key="4" value="4">Donnerstag</option>
              <option key="5" value="5">Freitag</option>
            </select>
          </div>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="time_from" class="form-label">Von</label>
              <input
                v-model="weekday_hour_entry.time_from"
                class="form-input"
                type="time"
                name="time_from"
                id="time_from"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="time_to" class="form-label">Bis</label>
              <input
                v-model="weekday_hour_entry.time_to"
                class="form-input"
                type="time"
                name="time_to"
                id="time_to"
              />
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="entry_val" class="form-label"
            >Wie viele Termine sollen parallel an diesem Wochentag erlaubt
            sein?</label
          >
          <input
            v-model="weekday_hour_entry.entry_val"
            class="form-input"
            type="text"
            name="entry_val"
            id="entry_val"
          />
        </div>
      </div>
      <div class="modal-meta">
        <a
          @click="create_weekday_hour_entry"
          class="button button-red button-100"
          >Eintrag erstellen ›</a
        >
      </div>
    </vue-final-modal>

    <vue-final-modal
      classes="modal-wrap"
      content-class="modal-inner"
      v-model="modal_add_day_hour_entry"
    >
      <svg
        @click="modal_add_day_hour_entry = false"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
      <div class="modal-head">
        <h3>Tag Zeitspanne hinzufügen</h3>
      </div>
      <div class="modal-body">
        <div class="form-wrap">
          <label for="day" class="form-label">Bitte den Tag auswählen:</label>
          <v-date-picker
            v-model="day_hour_entry.day"
            :first-day-of-week="2"
            locale="de"
            color="red"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="bg-white border px-2 py-1 rounded form-input"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="time_from" class="form-label">Von</label>
              <input
                v-model="day_hour_entry.time_from"
                class="form-input"
                type="time"
                name="time_from"
                id="time_from"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="time_to" class="form-label">Bis</label>
              <input
                v-model="day_hour_entry.time_to"
                class="form-input"
                type="time"
                name="time_to"
                id="time_to"
              />
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="entry_val" class="form-label"
            >Wie viele Termine sollen parallel an diesem Wochentag erlaubt
            sein?</label
          >
          <input
            v-model="day_hour_entry.entry_val"
            class="form-input"
            type="text"
            name="entry_val"
            id="entry_val"
          />
        </div>
      </div>
      <div class="modal-meta">
        <a @click="create_day_hour_entry" class="button button-red button-100"
          >Eintrag erstellen ›</a
        >
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
export default {
  name: "admin_location",
  data() {
    return {
      loading: true,
      location: {},
      opening_hours: [],
      opening_hour: {},
      modal_add_opening_hour: false,
      service_hours: [],
      service_hour: {},
      modal_add_service_hour: false,
      scheduling_entries_week: [],
      scheduling_entries_day: [],
      scheduling_entries_day_hour: [],
      scheduling_entries_weekday: [],
      scheduling_entries_weekday_hour: [],
      modal_add_day_entry: false,
      day_entry: {
        scheduling_type: "is_day",
        day: new Date(),
        entry_val: null,
      },
      modal_add_day_hour_entry: false,
      day_hour_entry: {
        scheduling_type: "is_day_hour",
        day: new Date(),
        time_from: null,
        time_to: null,
        entry_val: null,
      },
      modal_add_weekday_entry: false,
      weekday_entry: {
        scheduling_type: "is_weekday",
        weekday: "",
        entry_val: null,
      },
      modal_add_week_entry: false,
      week_entry: {
        scheduling_type: "is_day",
        cw: null,
        entry_val: null,
      },
      modal_add_weekday_hour_entry: false,
      weekday_hour_entry: {
        scheduling_type: "is_weekday_hour",
        weekday: "",
        time_from: null,
        time_to: null,
        entry_val: null,
      },
      languages: [],
    };
  },
  methods: {
    async get_data() {
      await this.$http
        .get(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then((response) => {
          this.location = response.data.location;
          this.location.name_i18n.forEach((n) => {
            this.location["name_i18n_" + n.lang] = n.val;
          });
          this.location.description_i18n.forEach((n) => {
            this.location["description_i18n_" + n.lang] = n.val;
          });
        });
      await this.$http
        .get(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/opening_hours",
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then((response) => {
          this.opening_hours = response.data.opening_hours;
        });
      await this.$http
        .get(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/service_hours",
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then((response) => {
          this.service_hours = response.data.service_hours;
        });
      await this.$http
        .get(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/scheduling_entries",
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then((response) => {
          this.scheduling_entries_day = [];
          this.scheduling_entries_day_hour = [];
          this.scheduling_entries_week = [];
          this.scheduling_entries_weekday = [];
          this.scheduling_entries_weekday_hour = [];
          response.data.scheduling_entries.forEach((entry) => {
            if (entry.scheduling_type == "is_day") {
              this.scheduling_entries_day.push(entry);
            } else if (entry.scheduling_type == "is_day_hour") {
              this.scheduling_entries_day_hour.push(entry);
            } else if (entry.scheduling_type == "is_week") {
              this.scheduling_entries_week.push(entry);
            } else if (entry.scheduling_type == "is_weekday") {
              this.scheduling_entries_weekday.push(entry);
            } else if (entry.scheduling_type == "is_weekday_hour") {
              this.scheduling_entries_weekday_hour.push(entry);
            }
          });
        });
      await this.$http
        .get(process.env.VUE_APP_BASE_API + "/admin/languages", {
          headers: { Authorization: this.$store.getters.get_token },
        })
        .then((response) => {
          this.languages = response.data.languages;
        });
      this.loading = false;
    },
    update_location() {
      this.$http
        .patch(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid,
          this.location,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then(() => {
          this.get_data();
          this.modal_edit = false;
          this.$notify({
            title: "Erfolgreich gespeichert.",
            type: "success",
          });
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    create_opening_hour() {
      this.$http
        .post(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/opening_hours",
          this.opening_hour,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then(() => {
          this.get_data();
          this.modal_add_opening_hour = false;
          this.opening_hour = {};
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success",
          });
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    delete_hour(hour) {
      if (confirm("Wirklich löschen?")) {
        this.$http
          .delete(
            process.env.VUE_APP_BASE_API +
              "/admin/locations/" +
              this.$route.params.uuid +
              "/opening_hours/" +
              hour.id,
            { headers: { Authorization: this.$store.getters.get_token } }
          )
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success",
            });
          })
          .catch((error) => {
            error.response.data.errors.forEach((value) => {
              this.$notify({
                title: value,
                type: "error",
              });
            });
          });
      }
    },
    create_service_hour() {
      this.$http
        .post(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/service_hours",
          this.service_hour,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then(() => {
          this.get_data();
          this.modal_add_service_hour = false;
          this.service_hour = {};
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success",
          });
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    delete_service_hour(hour) {
      if (confirm("Wirklich löschen?")) {
        this.$http
          .delete(
            process.env.VUE_APP_BASE_API +
              "/admin/locations/" +
              this.$route.params.uuid +
              "/service_hours/" +
              hour.id,
            { headers: { Authorization: this.$store.getters.get_token } }
          )
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success",
            });
          })
          .catch((error) => {
            error.response.data.errors.forEach((value) => {
              this.$notify({
                title: value,
                type: "error",
              });
            });
          });
      }
    },
    open_day_entry() {
      this.day_entry = {
        scheduling_type: "is_day",
        day: new Date(),
        entry_val: 1,
      };
      this.modal_add_day_entry = true;
    },
    open_day_hour_entry() {
      (this.day_hour_entry = {
        scheduling_type: "is_day_hour",
        day: new Date(),
        time_from: null,
        time_to: null,
        entry_val: null,
      }),
        (this.modal_add_day_hour_entry = true);
    },
    open_weekday_entry() {
      (this.weekday_entry = {
        scheduling_type: "is_weekday",
        weekday: "",
        entry_val: null,
      }),
        (this.modal_add_weekday_entry = true);
    },
    open_weekday_hour_entry() {
      (this.weekday_hour_entry = {
        scheduling_type: "is_weekday_hour",
        weekday: "",
        time_from: null,
        time_to: null,
        entry_val: null,
      }),
        (this.modal_add_weekday_hour_entry = true);
    },
    open_week_entry() {
      let now = new Date();
      let onejan = new Date(now.getFullYear(), 0, 1);
      let week = Math.ceil(
        ((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) /
          7
      );
      this.week_entry = {
        scheduling_type: "is_week",
        cw: week,
        entry_val: 1,
      };
      this.modal_add_week_entry = true;
    },
    create_day_entry() {
      this.$http
        .post(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/scheduling_entries",
          this.day_entry,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success",
          });
          this.modal_add_day_entry = false;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    create_week_entry() {
      this.$http
        .post(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/scheduling_entries",
          this.week_entry,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success",
          });
          this.modal_add_week_entry = false;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    create_weekday_entry() {
      this.$http
        .post(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/scheduling_entries",
          this.weekday_entry,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success",
          });
          this.modal_add_weekday_entry = false;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    create_weekday_hour_entry() {
      this.$http
        .post(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/scheduling_entries",
          this.weekday_hour_entry,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success",
          });
          this.modal_add_weekday_hour_entry = false;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    create_day_hour_entry() {
      this.$http
        .post(
          process.env.VUE_APP_BASE_API +
            "/admin/locations/" +
            this.$route.params.uuid +
            "/scheduling_entries",
          this.day_hour_entry,
          { headers: { Authorization: this.$store.getters.get_token } }
        )
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success",
          });
          this.modal_add_day_hour_entry = false;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              title: value,
              type: "error",
            });
          });
        });
    },
    delete_entry(entry) {
      if (confirm("Wirklich löschen?")) {
        this.$http
          .delete(
            process.env.VUE_APP_BASE_API +
              "/admin/locations/" +
              this.$route.params.uuid +
              "/scheduling_entries/" +
              entry.id,
            { headers: { Authorization: this.$store.getters.get_token } }
          )
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success",
            });
          })
          .catch((error) => {
            error.response.data.errors.forEach((value) => {
              this.$notify({
                title: value,
                type: "error",
              });
            });
          });
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.content-termine {
  h4 {
    margin: 20px 0 0 0;
    color: #9c2128;
  }

  p.sub {
    margin: 4px 0 8px 0;
    font-size: 12px;
  }
}

.entry-item {
  border: 1px solid #ddd;
  background: #f9f9f9;
  border-radius: 5px;
  margin: 2px 0;
  padding: 10px;
  position: relative;
  font-size: 14px;

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
  }

  svg:hover {
    opacity: 0.8;
  }
}
</style>
